<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Rubro
                  <v-autocomplete
                    v-model="filtro.rubro"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="rubros"
                    hide-details
                    clearable
                    autofocus
                    outlined
                    dense
                    @change="get_categorias()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Categoria
                  <v-autocomplete
                    v-model="filtro.categoria"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="categorias"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      
      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="lineas"
        :loading="load"
        :search="search"
        sort-by="nombre"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <v-text-field
                v-model="search"
                label="Buscar"
                hide-details
                single-line
                outlined
                dense
              >
                <template v-slot:append>
                  <v-icon
                    class="mt-1"
                    title="Buscar en los datos filtrados"
                    small
                  >
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="primary"
            title="Editar"
            class="mr-2"
            small
            @click="open_dialog(item)"
          >
            fas fa-pen
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <Linea
      v-model="dialog"
      :p_nueva="nueva"
      :p_linea="linea"
      :p_rubros="rubros"
      @editar="editar"
    />
  </v-row>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import Linea from '../../components/generales/Linea'
import { order_list_by } from '../../util/utils'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      nueva: false,
      dialog: false,
      rubros: [],
      categorias: [],
      lineas: [],
      headers: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Categoria', value: 'categoria_nombre' },
        { text: 'Rubro', value: 'rubro_nombre' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      linea: {
        codigo: null,
        nombre: null,
        rubro_codigo: null,
        categoria_codigo: null
      },
      filtro: {
        rubro: null,
        categoria: null,
        nombre: ''
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    this.rubros = []
    await this.$store.dispatch('rubros/get_rubros', { inhabilitado: 0 })
      .then((res) => {
        this.rubros = res.data
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error',
        })
      })
    // ordena los rubros por nombre desc
    order_list_by(this.rubros, 'nombre')
    this.$store.state.loading = false
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    Linea
  },
  methods: {
    async buscar () {
      this.load = true
      this.search = ''
      this.lineas = []
      await this.$store.dispatch('lineas/get_lineas', this.filtro)
        .then((res) => {
          this.lineas = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    },
    async get_categorias () {
      this.$store.state.loading = true
      this.categorias = []
      this.filtro.categoria = null
      const rubro = this.filtro.rubro
      if (rubro) {
        await this.$store.dispatch('categorias/get_categorias', { rubro: rubro })
          .then((res) => {
            this.categorias = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        order_list_by(this.categorias, 'nombre')
      }
      this.$store.state.loading = false
    },
    editar (item) {
      // actualiza la linea en la lista
      let linea = this.lineas.find(lin => lin.codigo == item.codigo)
      Object.assign(linea, item)
    },
    open_dialog (item) {
      if (item) {
        this.nueva = false
        this.linea = JSON.parse(JSON.stringify(item))
      } else {
        this.nueva = true
        this.linea = {
          codigo: null,
          nombre: null,
          rubro_codigo: null,
          categoria_codigo: null
        }
      }
      this.dialog = true
    },
    limpiar () {
      this.filtro = {
        rubro: null,
        categoria: null,
        nombre: ''
      }
    }
  }
}
</script>